export const icons = {
  "duo-file": "icons/svg/duotone/file.svg",
  "duo-arrow-up-right": "icons/svg/duotone/arrow-up-right.svg",
  "duo-moon": "icons/svg/duotone/moon.svg",
  "duo-brightness": "icons/svg/duotone/brightness.svg",
  "duo-arrow-left": "icons/svg/duotone/arrow-left.svg",
  "duo-arrow-right": "icons/svg/duotone/arrow-right.svg",
  "brand-twitter": "icons/svg/brands/twitter.svg",
  "brand-linkedin-in": "icons/svg/brands/linkedin-in.svg",
  "regular-star": "icons/svg/regular/star.svg",
  "duo-code-branch": "icons/svg/duotone/code-branch.svg",
};

export const size = {
  "2xs": 10,
  "xs": 12,
  "sm": 14,
  "lg": 20,
  "xl": 24,
  "2xl": 32,
  "1x": 16,
  "2x": 32,
  "3x": 48,
  "4x": 64,
  "5x": 80,
  "6x": 96,
  "7x": 112,
  "8x": 128,
  "9x": 144,
  "10x": 160,
};

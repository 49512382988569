import Image from 'next/image';
import { icons, size } from './Icons';

const SvgIcon = ({ iconName, sizeName = "1x", className = "", alt = "", filter = "" }) => {
  const iconSrc = icons[iconName];
  const iconSize = size[sizeName] || size["1x"];

  return iconSrc ? (
    <div className={className} style={{ position: 'relative', filter }}>
      <Image src={iconSrc} alt={alt} width={iconSize} height={iconSize} />
    </div>
  ) : null;
};

export default SvgIcon;
